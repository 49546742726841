import * as React from "react";
import { Link } from "gatsby";

import "./site.scss";
import "./syntax.scss";

const Layout = ({children}) => {
    return (
        <div className="page">
            <header>
                <h1><Link to="/">temporus.me</Link></h1>
            </header>
            <main>
                {children}
            </main>
            <footer>
                Site color theme based on the &nbsp;
                <a href="https://horizontheme.netlify.app/">horizon theme</a>
                &nbsp; by <a href="https://github.com/jolaleye">Jonathan Olaleye</a>
            </footer>
        </div>
    );
}

export default Layout;