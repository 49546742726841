import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import { getFormattedDateString } from "../components/helpers";

const edgeToEntry = (edge) => {
  var date = new Date(edge.node.frontmatter.publishdate);
  var datestr = getFormattedDateString(date);
  var link = edge.node.fields.slug;

  return (
    <li key={edge.node.id}>
      <span className="date">{datestr}</span>
      <span className="title"><Link to={link}>{edge.node.frontmatter.title}</Link></span>
    </li>
  )
}

const blogPostList = (data) => {
  return (
      <ul className="blog-list">
        {data.allMdx.edges.map(edgeToEntry)}
      </ul>
  )
}

const IndexPage = ({data}) => {
  return (
    <Layout>
      <h1>Latest Posts</h1>
      {blogPostList(data)}
    </Layout>
  )
};

export default IndexPage;

export const pageQuery = graphql`
query MyQuery {
  allMdx(sort: {fields: frontmatter___publishdate, order: DESC}) {
    edges {
      node {
        id
        frontmatter {
          title
          publishdate
        }
        fields {
          slug
        }
      }
    }
  }
}
`;
