export const leftPad = (pad, count, str) => {
  if (str.length >= count) {
    return str;
  }

  var padding = "";
  for (var i = 0; i < (count - str.length); i++) {
    padding += pad;
  }
  return padding + str;
}

export const getFormattedDateString = (date) => {
  var monthStr = leftPad('0', 2, `${date.getUTCMonth() + 1}`);
  var dayStr = leftPad('0', 2, `${date.getUTCDate()}`);
  return `${date.getUTCFullYear()}-${monthStr}-${dayStr}`;
}